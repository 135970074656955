<script lang="ts" setup>
</script>

<template>
  <div>
    <CCarousel hidden lg:block :items-to-show="1" :autoplay="2500" :transition="500" pause-autoplay-on-hover wrap-around h-562px>
      <CSlide v-for="slide in 1" :key="slide">
        <div grid grid-cols-2 h-562px w-full bg-app-bg-gray>
          <div flex items-center justify-center pl-32px>
            <div flex flex-col items-start justify-center text="40px text-app-text-gray-dark" font-extrabold text-start>
              <h1>Vstupte do světa designu,</h1>
              <h1>kde se setkáva krása a funkčnost</h1>
              <NuxtLink to="/realizace" text-32px underline font-medium mt-48px>
                Realizace
              </NuxtLink>
            </div>
          </div>
          <div flex items-end justify-end w-full>
            <NuxtImg src="/images/categories/bed.png" w="3/4" max-h-462px object-cover z-1 />
          </div>
        </div>
      </CSlide>
      <template #addons>
        <CPagination />
      </template>
    </CCarousel>
    <div relative flex flex-col lg:hidden min-h-448px lg:min-h-562px>
      <NuxtImg height="268" block lg:hidden src="/images/categories/bed.png" preload />
      <div min-h-180px relative block lg:hidden h-full text-app-text-gray-dark pl-24px pt-24px pb-32px pr-80px bg-app-bg-gray z-2>
        <NuxtImg absolute right-0 top-0 h-full w="1/2" z--1 src="/images/carousel-bg.png" preload />
        <h1 text-24px font-extrabold z-10 line-clamp-3 max-w="100% sm:2/3">
          Vstupte do světa designu, kde se setkává krása a funkčnost
        </h1>
        <div mt-12px>
          <NuxtLink to="/realizace" text-18px font-medium underline>
            Zobrazit realizace
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.carousel__pagination-button::after {
  border-radius: 100% !important;
  height: 10px;
  width: 10px;
  background-color: rgba(0,0,0,0.25);
}

.carousel__pagination-button--active::after {
  background-color: black;
}

.carousel__pagination {
  margin-top: -32px !important;
  z-index: 1 !important;
  position: relative;
}
</style>
